import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Breadcrumbs } from "@molecules";
import { Container, Text, Image, Icon, AnimateIn } from "@atoms";
import { AppLink } from "@base";

const variants = {
  enter: {
    opacity: 1,
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 0.3,
    },
  },
};

const Hero = ({ button, caption, crumbs, current, heading, image, links }) => {
  const [hovered, setHovered] = useState(false);

  const hasWindow = typeof window !== "undefined";

  const isAnchor = url => url.charAt(0) === "#";

  const scrollTo = target => {
    if (hasWindow) {
      document.getElementById(target)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const site = process.env.GATSBY_CRAFT_HANDLE;
  const showSubnav = site === "openStudios" || site === "watsonville";

  return (
    <section
      className={classNames("relative flex flex-col pt-12 md:pt-16", {
        "mb-12": !image,
      })}
    >
      <Container className="relative z-10 flex flex-col items-start gap-8">
        <div className="relative z-10 space-y-12 md:space-y-16 lg:-left-16">
          {!showSubnav && (
            <Breadcrumbs
              crumbs={crumbs}
              current={current}
              className="lg:left-[1.65rem]"
            />
          )}
          <div className="relative -left-1 max-w-[40rem] pl-1 lg:-left-0 lg:pl-6">
            <Text
              variant="headingSm"
              className="bg-heading inline whitespace-pre-line pr-3 md:pr-6"
            >
              {heading}
            </Text>
          </div>
        </div>
        {/* background image */}
        {image && (
          <div className="relative -mt-12 h-full w-full md:-mt-20">
            {button?.url && (
              <AppLink
                to={isAnchor(button.url) ? null : button.url}
                onClick={() =>
                  isAnchor(button.url) ? scrollTo(button.url.split("#")[1]) : {}
                }
                className="absolute bottom-0 right-0 z-10 lg:-top-[2.875rem] lg:bottom-auto"
              >
                <span className="block bg-green py-4 px-6 text-sm font-bold text-black duration-300 hover:bg-black hover:text-white">
                  {button.text}
                </span>
              </AppLink>
            )}
            <AnimateIn
              preset="fadeUpFast"
              threshold={0}
              className="relative z-0 aspect-[2/1] h-full w-full overflow-hidden"
            >
              {caption && (
                <div
                  className={classNames(
                    "absolute inset-0 z-20 p-4 duration-300",
                    {
                      "bg-black/60": hovered,
                    }
                  )}
                >
                  <div className="relative flex h-full w-full items-end justify-end gap-4">
                    <m.div
                      initial="exit"
                      animate={hovered ? "enter" : "exit"}
                      variants={variants}
                      className="pointer-events-none text-right"
                    >
                      <Text variant="xs" className="text-white">
                        {caption}
                      </Text>
                    </m.div>
                    <m.button
                      type="button"
                      onTap={() => setHovered(!hovered)}
                      onMouseEnter={() => setHovered(true)}
                      onMouseLeave={() => setHovered(false)}
                      className="h-4 w-4"
                    >
                      <Icon
                        name="info"
                        className="h-full w-full drop-shadow-sm duration-300"
                      />
                    </m.button>
                  </div>
                </div>
              )}
              <Image
                image={image}
                fill
                eager
                preserveAspectRatio
                className={classNames({
                  "blur-[2px]": hovered,
                })}
              />
            </AnimateIn>
          </div>
        )}
        {/* link list */}
        {!!links?.length && (
          <div className="flex flex-wrap gap-8">
            {links.map(item => {
              const { uid, link } = item;
              const anchor = item.link?.url?.split("#")[1];
              return (
                <AppLink
                  key={uid}
                  onClick={() => (anchor ? scrollTo(anchor) : {})}
                  to={anchor ? null : link.url}
                  className="font-bold underline decoration-transparent decoration-2 underline-offset-4 duration-300 hover:decoration-green"
                >
                  {link.text}
                </AppLink>
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

export default Hero;

Hero.defaultProps = {};
